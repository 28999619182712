import React from "react";
import { TypeAnimation } from "react-type-animation";
import "./Profile.css"
export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icons">
              <a href="#">
                <i className="fa fa-facebook-square"></i>
              </a>
              <a href="#">
                <i className="fa fa-google-plus-square"></i>
              </a>
              <a href="#">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fa fa-youtube-square"></i>
              </a>
              <a href="#">
                <i className="fa fa-twitter-square"></i>
              </a>
            </div>
            <div className="profile-details-name">
            <div>
  <span className="primary-text">
              {" "}
              Hello, I'M <span className="highlighted-text">Naseer Ahmad</span>
            </span>
            </div>
          
            <div className="profile-details-role">
              <span className="primary-text">
                {" "}
                <h1>
                  <TypeAnimation
                    sequence={[
                      "Ethusiastic Dev 🔴", // Types 'One'
                      1000, // Waits 1s
                      "Front-end Developer 💻", // Deletes 'One' and types 'Two'
                      2000, // Waits 2s
                      "Cross platform Dev 🌐 ", // Types 'Three' without deleting 'Two'
                      2000, // Waits 2s
                      "React/React Native Dev 📱", // Types 'Three' without deleting 'Two'
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{alignSelf:"center"}}
                  />
                </h1>
                <span className="profile-role-tagline">
                Crafting User Experiences, One Line of Code at a Time
                </span>
              </span>
            </div>
            <div className="profile-options">
              <button className="btn primary-btn">
                {""}
                Hire Me{" "}
              </button>
              <a href="cv.pdf" download="cv cv.pdf">
                <button className="btn highlighted-btn">Get Resume</button>
              </a>
            </div>
          </div>
         
         
          </div>
         
        </div>
        <div className="profile-img">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
