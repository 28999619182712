import React, { useEffect,useRef } from 'react';
import { TOTAL_SCREENS } from './commonUtilits';
import { Subject } from 'rxjs';

const ScrollService = () => {
  const scrollServiceInstance = useRef({
    currentScreenBroadcaster: new Subject(),
    currentScreenFadeIn: new Subject(),
  });

  const checkCurrentScreenUnderViewport = () => {
    for (let screen of TOTAL_SCREENS) {
      let screenFromDom = document.getElementById(screen.screen_name);
      if (!screenFromDom) continue;
      let fullyVisible = isElementInView(screenFromDom, 'complete');
      let partiallyVisible = isElementInView(screenFromDom, 'partial');
      if (fullyVisible || partiallyVisible) {
        if (partiallyVisible && !screen.alreadyRendered) {
          scrollServiceInstance.current.currentScreenFadeIn.next({
            fadeInScreen: screen.screen_name,
          });
          screen['alreadyRendered'] = true;
          break;
        }
        if (fullyVisible) {
          scrollServiceInstance.current.currentScreenBroadcaster.next({
            screenInView: screen.screen_name,
          });
          break;
        }
      }
    }
  };

  const scrollToHireMe = () => {
    let contactMeScreen = document.getElementById('Contact Me');
    if (contactMeScreen) {
      contactMeScreen.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToHome = () => {
    let homeScreen = document.getElementById('Home');
    if (homeScreen) {
      homeScreen.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isElementInView = (elem, type) => {
    let rec = elem.getBoundingClientRect();
    let elementTop = rec.top;
    let elementBottom = rec.bottom;
    let partiallyVisible = elementTop < window.innerHeight && elementBottom >= 0;
    let completelyVisible = elementTop >= 0 && elementBottom <= window.innerHeight;
    switch (type) {
      case 'partial':
        return partiallyVisible;
      case 'complete':
        return completelyVisible;
      default:
        return false;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkCurrentScreenUnderViewport();
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollServiceInstance.current; // Return the initialized Subject
};

export default ScrollService;
